import { AccessPermissions } from '../__generated__/graphql'
import { ROUTES } from '../constants'
import type { SessionRichUser } from '../hooks/session/use-load-user'
import type { MenuConfig } from '../types/menu'
import { MenuCategories } from './top-menu'

const subMenuConfig = {
  [MenuCategories.enterprise]: {
    items: [
      {
        id: 'integrants',
        translateKey: 'submenu:integrants',
        href: ROUTES.integrants,
        accessPermission: AccessPermissions.GetIntegrantList,
      },
      {
        id: 'companies',
        translateKey: 'submenu:companies',
        href: ROUTES.companies,
        accessPermission: AccessPermissions.GetClientList,
      },
      {
        id: 'users',
        translateKey: 'submenu:users',
        href: ROUTES.users,
        accessPermission: AccessPermissions.GetUserList,
      },
      {
        id: 'products',
        translateKey: 'submenu:products',
        href: ROUTES.products,
        accessPermission: AccessPermissions.GetProductList,
      },
      {
        id: 'service-invoices',
        translateKey: 'submenu:service-invoices',
        href: ROUTES.serviceInvoices,
        accessPermission: AccessPermissions.GetServiceInvoicesList,
      },
      {
        id: 'teams',
        translateKey: 'submenu:teams',
        href: ROUTES.teams,
        accessPermission: AccessPermissions.GetTeamList,
      },
      {
        id: 'systems',
        translateKey: 'submenu:systems',
        href: '/systems',
        accessPermission: AccessPermissions.GetSystemList,
      },
      {
        id: 'games',
        translateKey: 'submenu:games',
        href: '/games',
        accessPermission: AccessPermissions.GetGameList,
      },
      {
        id: 'locations',
        translateKey: 'submenu:locations',
        href: '/locations',
        accessPermission: AccessPermissions.GetLocationList,
      },
      {
        id: 'analytics',
        translateKey: 'submenu:analytics',
        href: '/analytics',
        accessPermission: AccessPermissions.GetReportList,
      },
    ],
  },
  [MenuCategories.jackpots]: {
    items: [
      {
        id: 'jackpots',
        translateKey: 'submenu:jackpots',
        href: ROUTES.jackpots,
        accessPermission: AccessPermissions.GetJackpotList,
      },
      {
        id: 'levels',
        translateKey: 'submenu:levels',
        href: ROUTES.levels,
        accessPermission: AccessPermissions.GetLevelList,
      },
      {
        id: 'hits',
        translateKey: 'submenu:hits',
        href: ROUTES.hits,
        accessPermission: AccessPermissions.GetHitList,
      },
      {
        id: 'templates',
        translateKey: 'submenu:templates',
        href: ROUTES.templates,
        accessPermission: AccessPermissions.GetTemplateList,
      },
    ],
  },
  [MenuCategories.help]: {
    items: [
      {
        id: 'about',
        translateKey: 'submenu:about',
        href: '/about',
      },
      {
        id: 'currencies',
        translateKey: 'submenu:currencies',
        href: '/currencies',
      },
      {
        id: 'widget-demo',
        translateKey: 'submenu:widget-demo',
        href: '/widget-demo',
        accessPermission: (user: SessionRichUser) => user.isJA,
      },
    ],
  },
  [MenuCategories.visualisation]: {
    items: [
      {
        id: 'configuration',
        translateKey: 'submenu:configuration',
        href: '/configuration',
      },
    ],
  },
}

export const getSubMenuConfig = (): MenuConfig => subMenuConfig
